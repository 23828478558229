import { Auth0Provider } from '@auth0/auth0-react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Auth0Provider
        domain="dev-5mmk4yp74poaayoh.us.auth0.com"
        clientId="OTKA1yeF4aUyZSLYz9qiRwH8E9WckDaZ"
        authorizationParams={{
            redirect_uri: window.location.origin,
        }}
    >
        <App />
    </Auth0Provider>,
);
