type SamplesType = {
    [key: string]: string[];
};

export const samples: SamplesType = {
    en: [
        'Pi is greater than 4.',
        'Milk tastes good.',
        'Columbus discovered India.',
        'Mickey Mouse is an elephant, and Minnie Mouse is a cat.',
        'Japan is ranked within the top 20 in the FIFA rankings.',
        'Van Gogh composed Fur Elise.',
        'Thomas Jefferson is the first President of the United States.',
    ],
    ja: [
        '円周率は4より大きい',
        '牛乳はおいしい',
        'コロンブスはインドを発見した',
        'ミッキーマウスは象であり、ミニーマウスは猫である',
        '日本はFIFAランク20位以内である',
        'ゴッホは「エリーゼのため」にを作曲した',
        '2023年時点で、日本の消費税は10%である',
    ],
};
