import { Button, Modal, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react';

const DeleteIdBox = styled(Box)(({ theme }) => ({
    background: theme.palette.background.default,
    width: '40%',
    minWidth: '240px',
    margin: '16vh auto 0',
    padding: '1rem',
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    margin: 'normal',
    width: '100%',
}));

type DeleteIdModalProps = {
    openModal: boolean;
    onClose: () => void;
    onDelete: () => void;
    selectedId: string | undefined;
};

const DeleteIdModal: React.FC<DeleteIdModalProps> = (props) => {
    return (
        <Modal open={props.openModal} onClose={props.onClose}>
            <DeleteIdBox>
                <Typography variant="h5" fontWeight="bold">
                    Delete Knowledge Id
                </Typography>
                <Typography variant="h6" fontWeight="bold">
                    ID
                </Typography>
                <StyledTypography>{props.selectedId}</StyledTypography>
                <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button variant="contained" sx={{ width: 'max-content' }} onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{ width: 'max-content' }}
                        onClick={() => {
                            props.onDelete();
                            props.onClose();
                        }}
                    >
                        Revoke
                    </Button>
                </Stack>
            </DeleteIdBox>
        </Modal>
    );
};

export default DeleteIdModal;
