import { useAuth0 } from '@auth0/auth0-react';
import { Box, Table, TableContainer } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { getApiKey, getDynamoDBClient } from '../../utils/databaseHandler';

const Container = styled(Box)(({ theme }) => ({
    maxWidth: 'max-content',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

const extractId = (input: string): string => {
    const parts = input.split('|');
    return parts.length > 1 ? parts[1] : '';
};

const APIKeys: React.FC = () => {
    const { user, getIdTokenClaims } = useAuth0();
    const [apiKey, setApiKey] = useState<any>();

    useEffect(() => {
        const fetchApiKey = async () => {
            if (user?.sub) {
                const idTokenRet = await getIdTokenClaims();
                const dynamoDBClient = idTokenRet && getDynamoDBClient(idTokenRet.__raw);
                const apiKeyRet = dynamoDBClient && (await getApiKey(extractId(user.sub), dynamoDBClient));
                setApiKey(apiKeyRet);
            }
        };
        fetchApiKey();
    }, [user, getIdTokenClaims]);

    return (
        <Container>
            <h1>API Key</h1>
            <TableContainer component={Box}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Key</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {apiKey && (
                            <TableRow key={apiKey.api_key_id.S}>
                                <TableCell component="th" scope="row">
                                    {apiKey.api_key_id.S}
                                </TableCell>
                                <TableCell>{apiKey.key.S}</TableCell>
                                <TableCell>{apiKey.created.S}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default APIKeys;
