import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const Login: React.FC = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <Grid
            container
            alignItems='center'
            justifyContent='center'
            direction="column"
        >
            <Grid item
                sx={{ width: "320px" }}
            >
                <Paper sx={{ mt: 7, mb: 5 }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item sx={{ mx: 3, my: 3 }} >
                            <Typography variant="h3">
                                LLM<br />
                                FactChecker<br />
                            </Typography>

                            <Grid item sx={{ ml: -3 }}>
                                <img src="../logo_factchecker.png" alt="Logo" width="50%" />
                            </Grid>

                            <Grid item sx={{ mt: 6 }}>
                                <Typography>
                                    LLM FactChecker facilitates fact-checking of text written by human or Generative AI based on Web or custom knowledge.<br />
                                </Typography>
                                <Typography sx={{ mt: 3 }}>
                                    LLM FactCheckerは, 人間やAIが作成したテキストの真偽性をWebや与えられた知識を用いて検証するためのソリューションです.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    loginWithRedirect();
                                }}
                                sx={{ mt: 2, mb: 3 }}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item sx={{ mb: 3 }}>
                <a href="https://tdailab.com">
                    <img src="../logo_tdai.png" alt="Logo" />
                </a>
            </Grid>
        </Grid >
    );
};


export default Login;
