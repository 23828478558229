import { useAuth0 } from '@auth0/auth0-react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Card, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useLayoutEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getUsageApi } from '../../utils/apiHandler';
import { getApiKey, getDynamoDBClient } from '../../utils/databaseHandler';

const Container = styled(Box)(() => ({
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

interface UsageData {
    date: string;
    requests: number;
}

const extractId = (input: string): string => {
    const parts = input.split('|');
    return parts.length > 1 ? parts[1] : '';
};

const Usage: React.FC = () => {
    const { user, getIdTokenClaims } = useAuth0();

    const [usage, setUsage] = useState<UsageData[]>();
    const [displayMonth, setDisplayMonth] = useState<Date>(new Date());

    useLayoutEffect(() => {
        const fetchData = async () => {
            if (user?.sub) {
                try {
                    const idTokenRet = await getIdTokenClaims();
                    const dynamoDBClient = idTokenRet && getDynamoDBClient(idTokenRet.__raw);
                    const apiKey = dynamoDBClient && (await getApiKey(extractId(user.sub), dynamoDBClient));
                    if (apiKey?.api_key_id?.S) {
                        const usages = await getUsage(apiKey.api_key_id.S);
                        setUsage(usages);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        };

        fetchData();
    }, [displayMonth, user, getIdTokenClaims]);

    const generateDateArray = (start: Date, end: Date) => {
        const arr = [];
        for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(`${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt.getDate().toString().padStart(2, '0')}`);
        }
        return arr;
    };

    const getUsage = async (apiKeyId: string) => {
        const startDate = new Date(displayMonth);
        const endDate = new Date(displayMonth);
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
        const start = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate
            .getDate()
            .toString()
            .padStart(2, '0')}`;
        const end = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate
            .getDate()
            .toString()
            .padStart(2, '0')}`;
        const dates = generateDateArray(startDate, endDate);

        try {
            const res = await getUsageApi(apiKeyId, start, end);
            const requests = res.map((item: any) => item.used);
            const usages = dates.map((date, index) => ({ date: date, requests: requests[index] ? requests[index] : 0 }));
            return usages;
        } catch (err) {
            console.log(err);
        }
    };

    const totalRequests = usage?.reduce((sum, item) => sum + item.requests, 0);

    return (
        <Container>
            <h1>Usage</h1>
            {usage && usage.length > 0 ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <h2>Total Monthly Requests: {totalRequests}</h2>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}>
                            <IconButton
                                onClick={() => {
                                    setDisplayMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
                                }}
                            >
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                            <Card sx={{ width: '7rem', textAlign: 'center', padding: '0.5rem', height: 'max-content' }}>
                                {Intl.DateTimeFormat('en', { month: 'long' }).format(displayMonth)}
                            </Card>
                            <IconButton
                                onClick={() => {
                                    setDisplayMonth((prev) => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
                                }}
                                disabled={
                                    displayMonth.getFullYear() === new Date().getFullYear() && displayMonth.getMonth() === new Date().getMonth()
                                }
                            >
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <ResponsiveContainer width="95%" height={400}>
                        <BarChart data={usage} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis dataKey="requests" />
                            <Tooltip />
                            <Bar dataKey="requests" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </>
            ) : (
                <p>No usage data available.</p>
            )}
        </Container>
    );
};

export default Usage;
