import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Card } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Output } from '../../utils/apiHandler';

const StyledClaimBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    paddingBottom: '1rem',
}));

const StyledBox = styled(Box)(() => ({ display: 'flex', flexDirection: 'row', gap: '0.5rem', alignItems: 'center' }));

const StyledPaper = styled(Paper)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
}));

type OutputProps = {
    data: Output | undefined;
};

const ClaimBox: React.FC<{ claim: string; factuality: boolean | null }> = ({ claim, factuality }) => {
    return (
        <StyledClaimBox>
            {factuality !== null ? (
                factuality ? (
                    <>
                        <StyledBox>
                            <CheckIcon color="success" sx={{ width: '2rem' }} />
                            <Typography variant="h6" sx={{ color: 'success.main' }}>
                                Factual
                            </Typography>
                        </StyledBox>
                        <Typography variant="h6" sx={{ marginLeft: '2.5rem' }}>
                            {claim}
                        </Typography>
                    </>
                ) : (
                    <>
                        <StyledBox>
                            <CloseIcon color="error" sx={{ width: '2rem' }} />
                            <Typography variant="h6" sx={{ color: 'error.main' }}>
                                Not Factual
                            </Typography>
                        </StyledBox>
                        <Typography variant="h6" sx={{ marginLeft: '2.5rem' }}>
                            {claim}
                        </Typography>
                    </>
                )
            ) : (
                <>
                    <StyledBox>
                        <PriorityHighIcon color="warning" sx={{ width: '2rem' }} />
                        <Typography variant="h6" sx={{ color: 'warning.main' }}>
                            Unknown
                        </Typography>
                    </StyledBox>
                    <Typography variant="h6" sx={{ marginLeft: '2.5rem' }}>
                        {claim}
                    </Typography>
                </>
            )}
        </StyledClaimBox>
    );
};

const OutputComponent: React.FC<OutputProps> = ({ data }) => {
    if (!data) {
        return null;
    }
    return (
        <Box sx={{ maxWidth: '90%', margin: 'auto' }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Target: {data?.target}
            </Typography>
            {data?.result.map((result, index) => (
                <StyledPaper key={index}>
                    <ClaimBox claim={result.claim} factuality={result.factuality.factuality} />
                    <Card id="reasoning" sx={{p: '1rem'}}>
                            <Typography variant="subtitle1" fontWeight='bold'>Reasoning</Typography>
                            <Typography>{result.factuality.reasoning}</Typography>
                    </Card>
                    {result.factuality.correction && (
                        <Card id="correction">
                                <Typography variant="subtitle1" fontWeight='bold'>Correction</Typography>
                                <Typography>{result.factuality.correction}</Typography>
                        </Card>
                    )}
                    <Card sx={{p: '1rem'}}>
                            <Typography fontWeight='bold'>Evidences</Typography>
                            {result.factuality.evidences.map((evidenceQuery, idx) => (
                                <Accordion key={idx} id="query-evidence">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
                                        <Typography>
                                            {idx + 1}. {evidenceQuery.query}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {evidenceQuery.evidence.map((evidence, evidenceIdx) => (
                                            <Accordion key={evidenceIdx} defaultExpanded>
                                                <AccordionSummary >
                                                    <Typography fontWeight='bold'>Evidence {evidenceIdx + 1}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{evidence.content}</Typography>
                                                    <Link href={evidence.source} target="_blank" rel="noopener" sx={{ wordBreak: 'break-all' }}>
                                                        {evidence.source}
                                                    </Link>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                    </Card>
                </StyledPaper>
            ))}
        </Box>
    );
};

export default OutputComponent;
