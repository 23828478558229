import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Container,
    Fab,
    FormGroup,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getDocument } from 'pdfjs-dist';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { samples } from '../../data/factCheckerSamples';
import { Output, check, isChecked, setup } from '../../utils/apiHandler';
import { addKnowledgeId, addUsage, getApiKey, getDynamoDBClient, getKnowledgeIds } from '../../utils/databaseHandler';
import OutputComponent from './FactCheckerResult';


type CheckProps = {
    isDemo: boolean;
    sidebarWidth: number
};

type KnowledgeSrcObjType = {
    [key: string]: [string | null, boolean];
};

const extractId = (input: string): string => {
    const parts = input.split('|');
    return parts.length > 1 ? parts[1] : '';
};

const transformKnowledgeIds = (knowledgeIds: any, transformed: any) => {
    knowledgeIds.forEach((item: any) => {
        const data = item.M;
        const name = data.name.S;
        const knowledgeId = data.knowledge_id.S;
        transformed[name] = [knowledgeId, false];
    });

    return transformed;
};

const knowledgeSrcObjDemo: KnowledgeSrcObjType = {
    Google: [null, true],
    ieyasu: ['file-C2687bWZzjqT0dffmHDGltbf', false],
    bread: ['file-SUeEYS2YdgjrMLazHLARpcij', false],
};
const gptModels = ['gpt-3.5-turbo-16k', 'gpt-4'];

const Check: React.FC<CheckProps> = (props) => {
    const inputRef = useRef<HTMLInputElement>(null!);
    const fileRef = useRef<HTMLInputElement>(null!);
    const scrollResultRef = useRef<HTMLDivElement>(null)

    const { user, getIdTokenClaims } = useAuth0();

    const [knowledgeSrcObj, setKnowledgeSrcObj] = useState<KnowledgeSrcObjType>({ Google: [null, true] });
    const [enteredText, setEnteredText] = useState('');
    const [result, setResult] = useState<Output>();
    const [isCalling, setIsCalling] = useState(false);
    const [checkingSrc, setCheckingSrc] = useState<string | undefined>();
    const [checkingIdx, setCheckingIdx] = useState<number>(0);
    const [elapsedTime, setElapsedTime] = useState(0);

    const [gptModel, setGptModel] = useState('gpt-3.5-turbo-16k');
    const [maxQueriesPerClaim, setMaxQueriesPerClaim] = useState(3);
    const [maxEvidencesPerQuery, setMaxEvidencesPerQuery] = useState(3);
    const [enteredTimeout, setEnteredTimeout] = useState(300);

    const [dbClient, setDbClient] = useState<any>();
    const [apiKey, setApiKey] = useState<any>()

    const [target, setTarget] = useState('')

    useEffect(() => {
        scrollResultRef.current && scrollResultRef.current.scrollIntoView({behavior: 'smooth', block: 'end'})
    }, [isCalling])

    useEffect(() => {
        const fecthKnowledgeIDs = async () => {
            if (user?.sub) {
                try {
                    const idTokenRet = await getIdTokenClaims();
                    const dynamoDBClient = idTokenRet && getDynamoDBClient(idTokenRet.__raw);
                    if (dynamoDBClient) {
                        setDbClient(dynamoDBClient);
                        const knowledgeIds = await getKnowledgeIds(extractId(user.sub), dynamoDBClient);
                        const apiKeyRet = await getApiKey(extractId(user.sub), dynamoDBClient)
                        props.isDemo ? setApiKey(process.env.REACT_APP_PUBLIC_FACTCHECKER_API_KEY!): setApiKey(apiKeyRet)
                        const transformedObj = transformKnowledgeIds(knowledgeIds, { Google: [null, true] });
                        props.isDemo ?setKnowledgeSrcObj(knowledgeSrcObjDemo) : setKnowledgeSrcObj(transformedObj);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        };
        fecthKnowledgeIDs();
    }, [user, props.isDemo, getIdTokenClaims]);


    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isCalling) {
            interval = setInterval(() => {
                setElapsedTime((prev) => prev + 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };

    }, [isCalling]);

    const handleClickCheck = async () => {
        setCheckingIdx(0);
        if (inputRef.current && user?.sub) {
            setTarget(inputRef.current.value)
            for (const [knowledgeSrc, [knowledgeId, checked]] of Object.entries(knowledgeSrcObj)) {
                if (checked) {
                    try {
                        isChecked(apiKey, extractId(user.sub), enteredTimeout);
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                        check(
                            apiKey,
                            knowledgeId ? 'my_knowledge' : 'google',
                            inputRef.current.value,
                            extractId(user.sub),
                            gptModel,
                            knowledgeId,
                            maxQueriesPerClaim,
                            maxEvidencesPerQuery,
                            );
                            
                        inputRef.current.value = "";
                        setIsCalling(true);
                        setCheckingSrc(knowledgeSrc);
                        const res = await isChecked(apiKey, extractId(user.sub), enteredTimeout);
                        setResult(res);
                        setCheckingIdx((prev) => prev + 1);
                        if (user.email && dbClient) {
                            console.log(dbClient)
                            const now = new Date()
                            const today = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now
                                .getDate()
                                .toString()
                                .padStart(2, '0')}`;
                                const factuality = typeof res.result[0].factuality.factuality === 'boolean' ? res.result[0].factuality.factuality : 'none'
                                await addUsage(user.email, res.target, factuality, today, dbClient)
                            } else {
                                console.log('user: ', user)
                            }
                        } catch (error) {
                            console.error('API call failed: ', error);
                        } finally {
                            setIsCalling(false);
                        }
                    }
                }
                setIsCalling(false);
        }
    };

    // TODO: pdf to text
    async function extractTextFromPDF(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = async (event) => {
                try {
                    const typedArray = new Uint8Array(event.target?.result as ArrayBuffer);
                    const loadingTask = getDocument(typedArray);
                    const pdf = await loadingTask.promise;
                    const maxPages = pdf.numPages;
                    let pdfText = '';

                    for (let pageNumber = 1; pageNumber <= maxPages; pageNumber++) {
                        const page = await pdf.getPage(pageNumber);
                        const content = await page.getTextContent();
                        const pageText = content.items.map((item) => ('str' in item ? item.str : '')).join('\n');
                        pdfText += pageText + '\n';
                    }

                    resolve(pdfText);
                } catch (error) {
                    reject(error);
                }
            };

            fileReader.onerror = (error) => {
                reject(error);
            };

            fileReader.readAsArrayBuffer(file);
        });
    }

    // TODO: PDF
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && user?.sub) {
            if (file.type === 'text/plain') {
                const text = await file.text();
                if (!text) {
                    console.error("No text")
                    return
                }
                const apiKey_ = apiKey ? apiKey : process.env.REACT_APP_PUBLIC_FACTCHECKER_API_KEY!
                const res = await setup(apiKey_, text);
                console.log(res);
                setKnowledgeSrcObj({ ...knowledgeSrcObj, [file.name]: [res.knowledge_id, false] });
                const now = new Date();
                const today = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
                await addKnowledgeId(
                    extractId(user.sub),
                    {
                        name: file.name,
                        knowledge_id: res.knowledge_id,
                        query_engine: 'chatgpt',
                        created: today,
                    },
                    dbClient,
                );
            } else if (file.type === 'application/pdf') {
                const text = await extractTextFromPDF(file);
                console.log(text);
            }
        }
    };

    const handleDownload = () => {
        const blob = new Blob([JSON.stringify(result, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'result.json';
        link.click();
        URL.revokeObjectURL(url);
    };

    const keySend = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            handleClickCheck();
        }
    };

    // tmp
    const handleCheckboxChange = (selectedKey: string) => {
        setKnowledgeSrcObj((prev) => {
            const updatedObj: KnowledgeSrcObjType = {};

            Object.keys(prev).forEach((key) => {
                // 選択されたキーのみtrueに設定し、他はすべてfalseに設定
                updatedObj[key] = [prev[key][0], selectedKey === key];
            });

            return updatedObj;
        });
    };

    const attachLink = (name: string) => {
        switch (name) {
            case 'ieyasu':
                return <Link href='https://ja.wikipedia.org/wiki/%E5%BE%B3%E5%B7%9D%E5%AE%B6%E5%BA%B7' target='_blank' rel='noopener'>徳川家康 wikipedia</Link>
            case 'bread':
                return <Link href='https://en.wikipedia.org/wiki/Bread' target='_blank' rel='noopener'>Bread wikipedia</Link>
            default:
                return name
        }
    }


    const knowledgeCheckboxes = Object.entries(knowledgeSrcObj).map(([key, value], idx) => (
        <FormGroup key={key}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value[1]}
                        onChange={() => {
                            // setKnowledgeSrcObj((prev) => {
                            //     const updatedValue: [string | null, boolean] = [value[0], !value[1]];
                            //     return {
                            //         ...prev,
                            //         [key]: updatedValue,
                            //     };
                            // });

                            //tmp
                            handleCheckboxChange(key)
                        }}
                    />
                }
                label={attachLink(key)}
            />
        </FormGroup>
    ));

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box height='90vh' sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {props.isDemo ? <h1>Playground (Demo)</h1> : <h1>Playground</h1>}
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '2rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <Card sx={{ p: '1rem' }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Available knowledge sources
                            </Typography>
                            {knowledgeCheckboxes}
                            {props.isDemo && (
                                <Typography fontSize="small" color="error" sx={{ marginTop: '1rem' }}>
                                    Disable in the demo version
                                </Typography>
                            )}
                            <Box sx={{ p: '1rem', width: 'max-content' }}>
                                <input
                                    type="file"
                                    ref={fileRef}
                                    accept=".pdf, .txt"
                                    disabled={props.isDemo}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                <Fab
                                    color="primary"
                                    size="small"
                                    sx={{ marginRight: '1rem' }}
                                    onClick={() => {
                                        fileRef.current?.click();
                                    }}
                                    disabled={props.isDemo}
                                >
                                    <AddIcon />
                                </Fab>
                                Setup your document
                            </Box>
                        </Card>
                    </Box>
                    <Box>
                        <Card sx={{ p: '1rem' }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Parameters
                            </Typography>
                            {props.isDemo && (
                                <Typography fontSize="small" color="error">
                                    Disable in the demo version
                                </Typography>
                            )}
                            <FormControl disabled={props.isDemo} sx={{ display: 'flex', gap: '1rem', m: '1rem' }}>
                                <InputLabel id="gpt-model">GPT Model</InputLabel>
                                <Select labelId="gpt-model" label="GPT Model" value={gptModel} onChange={(e) => setGptModel(e.target.value)}>
                                    {gptModels.map((model, idx) => (
                                        <MenuItem key={idx} value={model}>
                                            {model}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Accordion>
                                    <AccordionSummary sx={{ textAlign: 'left' }} expandIcon={<ExpandMoreIcon />}>
                                        <SettingsIcon sx={{ mx: 1 }} /> <Typography>Advanced</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                        <TextField
                                            label="Max queries per claim"
                                            type="number"
                                            variant="outlined"
                                            disabled={props.isDemo}
                                            value={maxQueriesPerClaim}
                                            onChange={(e) => setMaxQueriesPerClaim(parseInt(e.target.value))}
                                        />
                                        <TextField
                                            label="Max evidences per query"
                                            type="number"
                                            variant="outlined"
                                            disabled={props.isDemo}
                                            value={maxEvidencesPerQuery}
                                            onChange={(e) => setMaxEvidencesPerQuery(parseInt(e.target.value))}
                                        />
                                        <TextField
                                            label="Timeout (s)"
                                            type="number"
                                            variant="outlined"
                                            disabled={props.isDemo}
                                            value={enteredTimeout}
                                            onChange={(e) => setEnteredTimeout(parseInt(e.target.value))}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </FormControl>
                        </Card>
                    </Box>
                    <Box>
                        <Card sx={{ p: '1rem' }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Samples
                            </Typography>
                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem', p: '1rem' }}>
                                {['en', 'ja'].map((lang, idx) => (
                                    <Accordion defaultExpanded={lang === 'ja'} key={idx}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>{lang === 'ja' ? '日本語' : 'English'}</AccordionSummary>
                                        <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
                                            {samples[lang].map((sample, idx) => (
                                                <Button
                                                    key={idx}
                                                    data-text={sample}
                                                    variant="outlined"
                                                    style={{ textTransform: 'none' }}
                                                    onClick={(e) => {
                                                        setEnteredText(e.currentTarget.dataset.text || '');
                                                        inputRef.current.value = e.currentTarget.dataset.text || '';
                                                        inputRef.current.focus();
                                                    }}
                                                >
                                                    {sample}
                                                </Button>
                                            ))}

                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Card>
                    </Box>
                </Box>

                {isCalling ? (
                    <Container  sx={{ textAlign: 'center', p: '5rem' }}>
                        <CircularProgress />
                        <Typography variant='h6' sx={{ mt: 2 }}>Target: { target}</Typography>
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Checking "{checkingSrc}" {checkingIdx + 1}/
                            {Object.values(knowledgeSrcObj)
                                .map((item) => item[1])
                                .reduce((a, b) => a + (b ? 1 : 0), 0)}
                            : {elapsedTime} s
                        </Typography>
                    </Container>
                ) : result ? (
                    <>
                        <Paper elevation={3} sx={{ position: 'relative', width: '100%', my: 2, p: 2 }}>
                            <OutputComponent data={result} />
                            <IconButton onClick={handleDownload} color="primary" sx={{ position: 'absolute', top: 8, right: 8 }}>
                                <DownloadIcon />
                            </IconButton>
                        </Paper>
                    </>
                ) : null}

                <div ref={scrollResultRef} />
            </Box>

            <Box sx={{ width: { xs: '100%', sm: '80%' }, bottom: 0, p: 2, bgcolor: 'background.paper', display: 'flex', alignItems: 'center', gap: 2 }}>
                <TextField
                    fullWidth
                    label="Target"
                    multiline
                    maxRows={4}
                    inputRef={inputRef}
                    onChange={(e) => {
                        setEnteredText(e.target.value);
                    }}
                    onKeyDown={(e) => keySend(e)}
                />
                <IconButton
                    onClick={handleClickCheck}
                    color="primary"
                    disabled={
                        enteredText === '' ||
                        !Object.values(knowledgeSrcObj)
                            .map((item) => item[1])
                            .includes(true)
                    }
                >
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Check;
