import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Document: FC = () => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/README.md`)
            .then((m) => m.text())
            .then((md) => {
                setMarkdown(md);
            });
    }, []);

    return (
        <Box maxWidth='800px' sx={{ paddingBottom: '2rem', overFlowY: 'scroll' }}>
            <h1>Document</h1>
            <Markdown
                children={markdown}
                components={{
                    code(props) {
                        const { children, className, node, ...rest } = props;
                        const match = /language-(\w+)/.exec(className || '');
                        const { ref, ...highlighterProps } = rest;
                        return match ? (
                            <SyntaxHighlighter
                                {...highlighterProps}
                                PreTag="div"
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                style={a11yDark}
                            />
                        ) : (
                            <code {...rest} className={className}>
                                {children}
                            </code>
                        );
                    },
                }}
            />
        </Box>
    );
};

export default Document;
