import axios from 'axios';

type Evidence = {
    content: string;
    source: string;
};

type EvidenceQuery = {
    query: string;
    evidence: Evidence[];
};

type Factuality = {
    reasoning: string;
    correction: null | string;
    factuality: boolean | null;
    evidences: EvidenceQuery[];
};

type Result = {
    claim: string;
    factuality: Factuality;
};

export type Output = {
    target: string;
    result: Result[];
};

export const getUsageApi = async (apiKeyId: string, start: string, end: string) => {
    const res = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE!}/admin`,
        {
            type: 'usage',
            apikey_id: apiKeyId,
            date_start: start,
            date_end: end,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );
    return res.data;
};

export const setup = async (apiKey: string, knowledgeContent: string) => {
    const res = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/setup`,
        {
            knowledge_base: 'my_knowledge',
            knowledge_base_llm: 'chatgpt',
            knowledge_content: knowledgeContent,
        },
        {
            headers: { 'content-type': 'application/json', 'x-api-key': apiKey },
        },
    );

    return res.data;
};

export const check = (
    apiKey: string,
    knowledgeBase: 'google' | 'my_knowledge',
    targets: string,
    saveId: string,
    gptModel: string = 'gpt-3.5-turbo-16k',
    knowledgeId: string | null = null,
    maxQueriesPerClaim: number = 3,
    maxEvidencesPerQuery: number = 3,
) => {
    axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/check`,
        {
            knowledge_base: knowledgeBase,
            knowledge_base_llm: 'chatgpt',
            knowledge_id: knowledgeId,
            targets: [targets],
            save_id: saveId,
            gpt_model: gptModel,
            max_queries_per_claim: maxQueriesPerClaim,
            max_evidences_per_query: maxEvidencesPerQuery,
        },
        {
            headers: {
                'content-type': 'application/json',
                'x-api-key': apiKey,
            },
        },
    );
};

export const isChecked = async (apiKey: string, saveId: string, timeout: number = 3600) => {
    let timer = 0;
    while (timer < timeout) {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL_BASE}/is_checked`,
            { save_id: saveId },
            {
                headers: {
                    'content-type': 'application/json',
                    'x-api-key': apiKey,
                },
            },
        );
        if (res.data) {
            return res.data[0];
        }
        timer += 1;
    }
};

export const deleteKnowledge = (apiKey: string, knowledgeId: string) => {
    axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/delete`,
        { knowledge_id: knowledgeId },
        {
            headers: {
                'content-type': 'application/json',
                'x-api-key': apiKey,
            },
        },
    );
};

export const checkMult = async (apiKey: string, timeout: number = 300) => {
    let timer = 0;
    while (timer < timeout) {
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL_BASE}/is_checked`,
                {},
                {
                    headers: {
                        'content-type': 'application/json',
                        'x-api-key': apiKey,
                    },
                },
            );

            if (res.data) {
                return res.data[0];
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }

        // 1秒待機
        await new Promise((resolve) => setTimeout(resolve, 1000));
        timer += 1;
    }
    throw new Error('API timed out');
};
