import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Table, TableContainer } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { deleteKnowledge } from '../../utils/apiHandler';
import { deleteKnowledgeId, getDynamoDBClient, getKnowledgeIds } from '../../utils/databaseHandler';
import DeleteIdModal from './DeleteIdModal';

const Container = styled(Box)(({ theme }) => ({
    maxWidth: 'max-content',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

const extractId = (input: string): string => {
    const parts = input.split('|');
    return parts.length > 1 ? parts[1] : '';
};

const KnowledgeIds: React.FC = () => {
    const { user, getIdTokenClaims } = useAuth0();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState<string | undefined>();
    const [knowledgeIds, setKnowledgeIds] = useState<any[] | null>([]);
    const [dbClient, setDbClient] = useState<any>();

    useEffect(() => {
        const fetchKnowledgeIds = async () => {
            if (user?.sub) {
                const idTokenRet = await getIdTokenClaims();
                const dynamoDBClient = idTokenRet && getDynamoDBClient(idTokenRet.__raw);
                dynamoDBClient && setDbClient(dynamoDBClient);
                const knowledgeIds = dynamoDBClient && (await getKnowledgeIds(extractId(user.sub), dynamoDBClient));
                knowledgeIds && setKnowledgeIds(knowledgeIds);
            }
        };
        fetchKnowledgeIds();
    }, [user, getIdTokenClaims]);

    const openModalHandler = (id: string) => {
        setSelectedId(id);
        setDeleteModalOpen(true);
    };

    const closeModalHandler = () => {
        setDeleteModalOpen(false);
    };

    const deleteIdHander = () => {
        if (user?.sub && selectedId) {
            deleteKnowledgeId(extractId(user.sub), selectedId, dbClient);
            deleteKnowledge(process.env.REACT_APP_PUBLIC_FACTCHECKER_API_KEY!, selectedId)
            setKnowledgeIds((prev) => prev!.filter((item) => item.M.knowledge_id.S !== selectedId));
        }
    };

    return (
        <Container>
            <h1>Knowledge IDs</h1>
            {knowledgeIds?.length ? (
                <TableContainer component={Box}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Knowledge ID</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Query Engine</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Created</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {knowledgeIds.map((row) => (
                                <TableRow key={row.M.knowledge_id.S}>
                                    <TableCell>{row.M.name.S}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.M.knowledge_id.S}
                                    </TableCell>
                                    <TableCell>{row.M.query_engine.S}</TableCell>
                                    <TableCell>{row.M.created.S}</TableCell>
                                    <TableCell>
                                        <IconButton aria-label="delete" onClick={() => openModalHandler(row.M.knowledge_id.S)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <p>No knowledge uploaded</p>
            )}
            <DeleteIdModal openModal={deleteModalOpen} onClose={closeModalHandler} onDelete={deleteIdHander} selectedId={selectedId} />
        </Container>
    );
};

export default KnowledgeIds;
