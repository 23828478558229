import { CreateTableCommand, DynamoDBClient, GetItemCommand, ScanCommand, UpdateItemCommand } from '@aws-sdk/client-dynamodb';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

export const getDynamoDBClient = (idToken: string) => {
    const client = new DynamoDBClient({
        region: process.env.REACT_APP_AWS_REGION!,
        credentials: fromCognitoIdentityPool({
            clientConfig: { region: process.env.REACT_APP_AWS_REGION! },
            identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID!,
            logins: {
                [process.env.REACT_APP_AUTH0_DOMAIN!]: idToken,
            },
        }),
    });
    return client;
};

export const createTable = async (client: DynamoDBClient) => {
    const command = new CreateTableCommand({
        TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
        AttributeDefinitions: [
            {
                AttributeName: 'google_uid',
                AttributeType: 'S',
            },
        ],
        KeySchema: [
            {
                AttributeName: 'google_uid',
                KeyType: 'HASH',
            },
        ],
        ProvisionedThroughput: {
            ReadCapacityUnits: 1,
            WriteCapacityUnits: 1,
        },
    });

    try {
        const response = await client.send(command);
        console.log('Table created successfully:', response);
    } catch (error) {
        console.error('Error creating table:', error);
    }
};

export const scanTable = async (tableName: string, client: DynamoDBClient) => {
    try {
        const params = {
            TableName: tableName,
        };

        const command = new ScanCommand(params);
        const response = await client.send(command);

        console.log('Table data: ', response.Items);
        return response.Items;
    } catch (error) {
        console.error('Error scanning table: ', error);
    }
};

export const addUsage = async (googleEmail: string, target: string, result: boolean | string, date: string, client: DynamoDBClient) => {
    const params = {
        TableName: 'FactChecker-Usage',
        Key: { googleEmail: { S: googleEmail } },
        UpdateExpression: 'SET usages = list_append(if_not_exists(usages, :empty_list), :new_kid)',
        ExpressionAttributeValues: {
            ':new_kid': {
                L: [
                    {
                        M: {
                            target: { S: target },
                            result: typeof result === 'boolean' ? { BOOL: result } : { S: result },
                            date: { S: date },
                        },
                    },
                ],
            },
            ':empty_list': { L: [] },
        },
    };

    try {
        await client.send(new UpdateItemCommand(params));
        console.log('Usage added to user:', googleEmail);
    } catch (error) {
        console.error('Error adding usage:', error);
    }
};

export const addKnowledgeId = async (
    google_uid: string,
    knowledgeId: { name: string; knowledge_id: string; query_engine: string; created: string },
    client: DynamoDBClient,
) => {
    const params = {
        TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
        Key: { google_uid: { S: google_uid } },
        UpdateExpression: 'SET knowledge_ids = list_append(if_not_exists(knowledge_ids, :empty_list), :new_kid)',
        ExpressionAttributeValues: {
            ':new_kid': {
                L: [
                    {
                        M: {
                            name: { S: knowledgeId.name },
                            knowledge_id: { S: knowledgeId.knowledge_id },
                            query_engine: { S: knowledgeId.query_engine },
                            created: { S: knowledgeId.created },
                        },
                    },
                ],
            },
            ':empty_list': { L: [] },
        },
    };

    try {
        await client.send(new UpdateItemCommand(params));
        console.log('Knowledge ID added to user:', google_uid);
    } catch (error) {
        console.error('Error adding Knowledge ID:', error);
    }
};

export const addApiKey = async (google_uid: string, apiKey: { api_key_id: string; key: string; created: string }, client: DynamoDBClient) => {
    const params = {
        TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
        Key: { google_uid: { S: google_uid } },
        UpdateExpression: 'SET api_key = :apiKey',
        ExpressionAttributeValues: {
            ':apiKey': {
                M: {
                    api_key_id: { S: apiKey.api_key_id },
                    key: { S: apiKey.key },
                    created: { S: apiKey.created },
                },
            },
        },
    };

    try {
        await client.send(new UpdateItemCommand(params));
        console.log('API key added to user:', google_uid);
    } catch (error) {
        console.error('Error adding API key:', error);
    }
};

export const getApiKey = async (google_uid: string, client: DynamoDBClient) => {
    const params = {
        TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
        Key: { google_uid: { S: google_uid } },
        ProjectionExpression: 'api_key',
    };

    try {
        const response = await client.send(new GetItemCommand(params));
        return response.Item?.api_key?.M || null;
    } catch (error) {
        console.error('Error getting API key:', error);
        return null;
    }
};

export const getKnowledgeIds = async (google_uid: string, client: DynamoDBClient) => {
    const params = {
        TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
        Key: { google_uid: { S: google_uid } },
        ProjectionExpression: 'knowledge_ids',
    };

    try {
        const response = await client.send(new GetItemCommand(params));
        return response.Item?.knowledge_ids?.L || null;
    } catch (error) {
        console.error('Error getting Knowledge IDs:', error);
        return null;
    }
};

export const deleteKnowledgeId = async (google_uid: string, knowledgeIdToDelete: string, client: DynamoDBClient) => {
    try {
        // まずは現在の knowledge_ids を取得
        const getItemParams = {
            TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
            Key: { google_uid: { S: google_uid } },
            ProjectionExpression: 'knowledge_ids',
        };
        const response = await client.send(new GetItemCommand(getItemParams));
        const currentKnowledgeIds = response.Item?.knowledge_ids?.L || [];

        // 削除する knowledgeId を除外
        const updatedKnowledgeIds = currentKnowledgeIds.filter((kid) => kid.M?.knowledge_id?.S !== knowledgeIdToDelete);

        // 更新された knowledge_ids でアイテムを更新
        const updateItemParams = {
            TableName: process.env.REACT_APP_DATABASE_TABLE_NAME!,
            Key: { google_uid: { S: google_uid } },
            UpdateExpression: 'SET knowledge_ids = :updatedKnowledgeIds',
            ExpressionAttributeValues: {
                ':updatedKnowledgeIds': { L: updatedKnowledgeIds },
            },
        };

        await client.send(new UpdateItemCommand(updateItemParams));
        console.log(`Knowledge ID ${knowledgeIdToDelete} deleted for user:`, google_uid);
    } catch (error) {
        console.error('Error deleting Knowledge ID:', error);
    }
};
