import { useAuth0 } from '@auth0/auth0-react';
import ArticleIcon from '@mui/icons-material/Article';
import BarChartIcon from '@mui/icons-material/BarChart';
import EmailIcon from '@mui/icons-material/Email';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LockIcon from '@mui/icons-material/Lock';
import PolicyIcon from '@mui/icons-material/Policy';
import { Button, Link } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const SidebarContainer = styled(Paper)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    maxWidth: '300px',
    borderRight: `1px solid ${theme.palette.divider}`,
}));

const LogoContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
}));

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const DisabledLink = styled('div')(({ theme }) => ({
    textDecoration: 'none',
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
}));

type SidebarProps = {
    isDemo: boolean;
    onClose?: () => void
};

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
    const { logout, user } = useAuth0();
    return (
        <SidebarContainer ref={ref} elevation={0} onClick={props.onClose}>
            <LogoContainer sx={{ ml: -1 }}>
                <img src="../logo_factchecker.png" alt="Logo" width="25%" />
            </LogoContainer>
            <List component="nav" aria-label="main mailbox folders" style={{ flexGrow: 1 }}>
                <StyledRouterLink to="/playground">
                    <ListItem>
                        <ListItemIcon>
                            <PolicyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Playground" />
                    </ListItem>
                </StyledRouterLink>
                {props.isDemo ? (
                    <DisabledLink>
                        <ListItem>
                            <ListItemIcon>
                                <LockIcon color="disabled" />
                            </ListItemIcon>
                            <ListItemText primary="API Key" />
                        </ListItem>
                    </DisabledLink>
                ) : (
                    <StyledRouterLink to="/api-key">
                        <ListItem>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="API Key" />
                        </ListItem>
                    </StyledRouterLink>
                )}
                {props.isDemo ? (
                    <DisabledLink>
                        <ListItem>
                            <ListItemIcon>
                                <FindInPageIcon color="disabled" />
                            </ListItemIcon>
                            <ListItemText primary="Knowledge IDs" />
                        </ListItem>
                    </DisabledLink>
                ) : (
                    <StyledRouterLink to="/knowledge-ids">
                        <ListItem>
                            <ListItemIcon>
                                <FindInPageIcon />
                            </ListItemIcon>
                            <ListItemText primary="Knowledge IDs" />
                        </ListItem>
                    </StyledRouterLink>
                )}
                {props.isDemo ? (
                    <DisabledLink>
                        <ListItem>
                            <ListItemIcon>
                                <BarChartIcon color="disabled" />
                            </ListItemIcon>
                            <ListItemText primary="Usage" />
                        </ListItem>
                    </DisabledLink>
                ) : (
                    <StyledRouterLink to="/usage">
                        <ListItem>
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Usage" />
                        </ListItem>
                    </StyledRouterLink>
                )}
                <StyledRouterLink to="/document">
                    <ListItem>
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Document" />
                    </ListItem>
                </StyledRouterLink>

                <Link
                    href="https://tdailab.com/contact"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    underline="none"
                >
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Contact" />
                    </ListItem>
                </Link>

            </List>
            <List sx={{ width: 'max-content', maxWidth: '300px' }}>
                <ListItem sx={{ width: 'max-content', maxWidth: '300px' }}>
                    <ListItemAvatar>
                        <Avatar src={user?.picture} alt="user profile" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={user?.name}
                        secondary={user?.email}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                        secondaryTypographyProps={{ fontSize: 'small' }}
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    />
                </ListItem>
                <ListItem>
                    <Button color="secondary" variant="contained" sx={{ margin: '0 auto' }} onClick={() => logout()}>
                        Logout
                    </Button>
                </ListItem>
            </List>
        </SidebarContainer>
    );
});

export default Sidebar;
