import styled from '@emotion/styled';
import { Box, Link, Typography } from '@mui/material';
import React from 'react';

const Container = styled(Box)(() => ({
    width: '80%',
    height: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
}));

const Contact: React.FC = () => {
    return (
        <Container>
            <h1>Contact</h1>
            <Typography>Please contact us for your business.</Typography>
            <Link href="mailto:info@tdailab.com" target="_blank" rel="noopener">
                info@tdailab.com
            </Link>
        </Container>
    );
};

export default Contact;
