import { useAuth0 } from '@auth0/auth0-react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, CircularProgress, Container, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import APIKey from './components/apikey/APIKey';
import Contact from './components/contact/Contact';
import Document from './components/document/Document';
import KnowledgeIds from './components/knowledgeId/KnowledgeIds';
import { default as Check } from './components/playground/Check';
import Usage from './components/usage/Usage';
import { getApiKey, getDynamoDBClient } from './utils/databaseHandler';

export const theme = createTheme({
    palette: {
        mode: 'dark',
        secondary: {
            main: '#fe3e5a',
        },
        success: {
            main: '#66bb6a',
        },
        error: {
            main: '#f44336',
        },
        warning: {
            main: '#ffa726',
        },
        info: {
            main: '#29b6f6',
        },
    },
});

const MainContent = styled('div')<{ sidebarWidth: number, isMobile: boolean }>(({ sidebarWidth, isMobile }) => ({
    width: `calc(100% - ${sidebarWidth}px)`,
    padding: isMobile ? '0 1rem': '0 2rem'
}));

const extractId = (input: string): string => {
    const parts = input.split('|');
    return parts.length > 1 ? parts[1] : '';
};

const App: React.FC = () => {
    const { isAuthenticated, user, isLoading, getIdTokenClaims } = useAuth0();
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [sidebarWidth, setSidebarWidth] = useState<number>(0);
    const [isDemo, setIsDemo] = useState(true);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        const validateUser = async () => {
            if (user?.sub) {
                const googleUid = extractId(user.sub);
                const idTokenRet = await getIdTokenClaims();
                const dynamoDBClient = idTokenRet && getDynamoDBClient(idTokenRet.__raw);
                const apiKey = dynamoDBClient && (await getApiKey(googleUid, dynamoDBClient));
                apiKey && setIsDemo(false);
            }
        };
        validateUser();
    }, [user, getIdTokenClaims]);

    useLayoutEffect(() => {
        if (sidebarRef.current) {
            setSidebarWidth(sidebarRef.current.offsetWidth);
        }
    }, []);

    if (isLoading) {
        return (
            <Container sx={{ textAlign: 'center', p: '5rem' }}>
                <CircularProgress />
                <Typography>Loading ...</Typography>
            </Container>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                {isAuthenticated ? (
                    <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'} }}>
                        {isMobile ? (
                            <Box sx={{display: 'block'}}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerToggle}
                                    sx={{ display: 'block'}}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Drawer
                                    anchor='left'
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                                    }}
                                >
                                    <Sidebar isDemo={isDemo} onClose={handleDrawerToggle} />
                                </Drawer>
                            </Box>
                        ) : (
                            <Sidebar ref={sidebarRef} isDemo={isDemo} />
                        )}
                        <MainContent sidebarWidth={isMobile ? 0 : sidebarWidth} isMobile={isMobile} >
                            <Routes>
                                <Route path="/playground" element={<Check isDemo={isDemo} sidebarWidth={sidebarWidth} />} />
                                <Route path="/api-key" element={<APIKey />} />
                                <Route path="/knowledge-ids" element={<KnowledgeIds />} />
                                <Route path="/usage" element={<Usage />} />
                                <Route path="/document" element={<Document />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/" element={<Check isDemo={isDemo} sidebarWidth={sidebarWidth}/>} />
                            </Routes>
                        </MainContent>
                    </Box>
                ) : (
                    <Login />
                )}
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
